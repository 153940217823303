.main {
  display: flex;
  position: relative;
  justify-content: center;

  .label {
    margin: 0;
    font-size: 1.2rem;
  }

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    font-family: Nunito, serif;
    color: var(--indigo-blue);
    cursor: pointer;
  }

  .select {
    left: 0;
    top: 0;
    position: absolute;
    visibility: hidden;
  }
}

.shop-cart {
  min-width: 240;
}

.shop-cart__empty-cart-image {
  height: 300px;
  max-width: 300px;
}

.shop-cart__line-item-image {
  width: 85px;
  height: 78px;
}

.shop-cart__line-item-remove {
  width: 32px;
  height: 32px;
}

.shop-cart__line-item-price {
  display: inline-grid;
  justify-content: end;
  padding-right: 5px;
}

.shop-cart__loader {
  width: 45px;
  height: 45px;
  left: calc(50% - 30px);
  position: relative;
}

@media screen and (min-width: 375px) {
  .shop-cart {
    min-width: 300px;
  }
}

@media screen and (min-width: 600px) {
  .shop-cart__loader {
    width: 45px;
    height: 45px;
    left: calc(100% - 90px);
    position: 'relative';
  }

  .shop-cart__line-item {
    display: grid;
    grid-template-columns: 85px 1fr 32px;
    grid-gap: 10px;
    align-items: center;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .shop-cart {
    min-width: 480px;
  }
}

@media screen and (min-width: 800px) {
  .shop-cart {
    min-width: 680px;
  }
}

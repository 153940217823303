.list_grid {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
}

.list_item {
  display: flex;
  width: 10rem;
  height: 7rem;
  border-radius: 20px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  @media screen and (max-width: 60rem) {
    width: 4rem;
    height: 4rem;
    }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .deleteBtn {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2ms;
  }

  &:hover {
    cursor: pointer;
  }

  .plus_frame {
    display: flex;
    width: 90%;
    height: 90%;
    border-radius: 20px;
    background-color: #F2F2F2;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease;

    .plus_icon {
      font-size: 40px;
      color: #001b44;
    }
  }
  .plus_frame:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
}
.list_item:hover .deleteBtn {
  opacity: 1;
}

.closeBtn {
  display: flex;
  align-self: flex-end;
  position: absolute !important;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}

.buttons {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.input_fields {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  min-width: 40rem;
  align-items: center;
  padding-bottom: 2rem;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  min-width: 30rem;
  padding-bottom: 1rem;
  justify-content: center;

  .deleteBtn {
    display: flex;
    align-self: flex-start;
    position: absolute;
    right: 10rem;
  }
}

.deleteSponsorModalContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  min-width: 30rem;

  .header {
    min-height: 3rem;
    font-weight: bold;
  }
}

.charity-details__text-content {
  max-width: 600px;
}

.charity-details__info {
  font-size: 10px;
}

.charity-details__buttons {
  min-width: 200px;
  height: 140px;
}

.charity-details__grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(138px, 1fr));
}

.charity-details__media {
  width: 100%;
  height: 50vh;
  min-height: 100px;
  max-height: 200px;
  border-radius: 10px;
  margin: 0;
  object-fit: cover;
}

@media screen and (min-width: 30em) {
  .charity-details__media {
    height: 60vh;
    min-height: 200px;
    max-height: 450px;
  }
}

@media screen and (min-width: 60em) {
  .charity-details__buttons {
    height: 124px;
  }
}

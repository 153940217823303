.listOfFiles {
  list-style-type: none;
  padding-left: 0;

  li {
    margin: 1.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .time {
      max-width: 5rem;
      margin: 0 1rem;
    }

    .buttons {
      display: flex;
      flex-direction: row;
    }
  }

}

.controlButtons {
  display: flex;
  justify-content: space-between;

  .save {
    background: #001b44;
  }
}

.number {
  max-width: 100px;
}

.confirmButtons {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.image {
  position: relative;
  margin: 20px;
}
.material_loader{
  margin-right: 10px;
  margin-top: 10px;
}

.image__cancel-button {
  position: absolute;
  right: -20px;
  top: -20px;
}

.image__cancel-button
  .MuiButtonBase-root
  .MuiIconButton-label
  .MuiSvgIcon-root {
  background: white;
  border-radius: 50%;
}

.image__actual-image {
  filter: blur(0px);
  transition: 1s;
}

.image__actual-image--blurred {
  animation: blurring 3s infinite;
}

@keyframes blurring {
  0% {
    filter: blur(4px);
  }
  50% {
    filter: blur(7px);
  }
  100% {
    filter: blur(4px);
  }
}

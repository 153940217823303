.modal {
  padding: 1.9rem 2% 1.6rem 2%;

  .modalContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .header {
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .body {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;
      margin: 0 1.2rem;

      .webcam {
        width: 5rem;
      }

      .modalText {
        text-align: start;
        max-width: 20rem;
        min-width: auto;
      }
    }

    .modalText {
      font-size: 1.25rem;
      min-width: 21rem;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .linkButton {
        font-size: 1rem;
        background-color: unset;
        border: unset;
        color: blue;
        cursor: pointer;
      }

      .startBtn {
        background-color: #0FAB15;
      }
    }
  }

  .confirmationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    gap: 2rem;
    min-width: 15rem;

    .header {
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
}
.animation-reveal {
  opacity: 0;
  animation: imageReveal 1.3s ease;
  animation-fill-mode: forwards;
}

.animation-gradient-sliding {
  background: rgb(197, 196, 207);
  background: linear-gradient(
    90deg,
    rgba(197, 196, 207, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-size: 400% 400%;
  animation: backgroundSliding 3s ease infinite;
}

@keyframes imageReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes backgroundSliding {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.breakRecord {
  max-width: 65rem;
  padding: 1rem;
}

.restrictComponent {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.picturedPersonCheckbox {
  display: flex;
  flex-direction: column;

  .description {
    font-size: 0.75rem;
    color: var(--dark-gray);
    margin-left: 2.625rem;
    margin-top: -0.375rem;
  }
}

.picturedPeople {
  padding: 1rem 0;

  .peopleRow {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    .icon {
      height: 2.5rem;
      margin-top: -1rem;
    }

    .empty {
      min-height: 2.5rem;
      min-width: 2.5rem;
    }
  }
}

.searchField {
  input {
    padding-top: 0.5rem;
  }
}

.cleanBtn {
  padding: unset;
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.tableHead {
  background-color: var(--indigo-blue);

  .cell {
    font-weight: bold;
    color: white !important;
    background-color: inherit;

    .box {
      color: white;
    }
  }
  color: white !important;
}

.tableContainer {
  margin-bottom: 1rem;
  flex: 1;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.createEditCampaignLink {
  width: 18rem;
  text-align: center;
  margin-bottom: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
}

.red {
  .cell {
    color: white;
  }
}

.iconButton {
  svg {
    fill: var(--pure-red);
  }
}

.editButton {
  svg {
    fill: var(--pure-green);
  }
}

.emailButton {
  svg {
    fill: var(--pure-green);
  }
}
.disableIcon {
  svg {
    fill: var(--gray);
  }
}

.row:nth-of-type(even) {
  background-color: var(--surface);
}

.deleteEventModalContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  min-width: 30rem;

  .header {
    min-height: 3rem;
    font-weight: bold;
  }
}

.closeBtn {
  display: flex !important;
  align-self: flex-end;
  position: absolute !important;
  right: 1rem;
  top: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
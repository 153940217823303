.submissionTitle {
    font-family: Nunito, serif;
    font-weight: 700;
    font-size: 1.25rem;
    color: #ff5252;
}
.buttonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0.5rem;
}
.bg-none {
  background: none;
}

.outline-none {
  outline: none;
}

@media screen and (min-width: 30em) {
  .bg-none-ns {
    background: none;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .bg-none-m {
    background: none;
  }
}

@media screen and (min-width: 60em) {
  .bg-none-l {
    background: none;
  }
}

.input-text.MuiTextField-root {
  margin-bottom: 1rem;
}

.input-text .MuiInputBase-root {
  background-color: var(--surface);
  border: 1.2px solid #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.input-text .MuiFilledInput-root.Mui-disabled {
  background-color: var(--surface);
  color: var(--dark-gray);
}

.input-text .MuiInputBase-root:hover,
.input-text .MuiInputBase-root.Mui-focused {
  background-color: var(--dark-surface);
}

.input-text .MuiInputBase-root,
.input-text .MuiFormLabel-root,
.input-text .MuiFormHelperText-root {
  color: var(--dark-gray);
  font-family: Nunito, serif;
}

.input-text .MuiInputBase-root.Mui-focused {
  border: 1.2px solid var(--indigo-blue);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input-text .MuiFormLabel-root.Mui-focused {
  color: var(--indigo-blue);
}

.input-text .MuiInputBase-root.Mui-error,
.input-text .MuiFormLabel-root.Mui-error,
.input-text .MuiFormHelperText-root.Mui-error {
  color: var(--pure-red);
}

.input-text .MuiInputBase-root.Mui-error.Mui-focused {
  border: 1.2px solid var(--pure-red);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.input-text .MuiInputBase-input {
  font-family: Nunito, serif;
}

.input-text input {
  height: unset;
}

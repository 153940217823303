.subTitle {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.formDiv {
    font-family: Nunito, serif;
    color: #595959;
    font-size: 1.25rem;
}
.inputRow {
    display: flex;
    flex-direction: row;
}
.name {
    margin-right: .5rem;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
}
.email {
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
}
.submitButton {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.textModal {
    text-align: center;
}
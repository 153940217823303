.quantity-update__icon-add.MuiButtonBase-root {
  padding: 8px;
  margin-left: -40px;
}

.quantity-update__button {
  max-width: 105px;
}

.quantity-update__icon-remove.MuiButtonBase-root {
  padding: 8px;
  margin-right: -40px;
}

.quantity-update__count {
  font-size: 20px;
  height: 30px;
  border-radius: 10px;
  -webkit-appearance: none;
  width: 100px;
  text-align: center;
  background: transparent;
}

@-moz-document url-prefix() {
  .quantity-update__count {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 60em) {
  .quantity-update__wrapper {
    align-items: end;
  }
}

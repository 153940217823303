.headerLink {
  border-radius: 50px;
  background: var(--pure-red);
  color: white;
  line-height: 30px;
  max-width: 200px;
  margin: 0 1rem;

  @media (max-width: 60em) {
    line-height: 15px;
  }
}

.image-gallery__media {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.image-gallery__arrow-back,
.image-gallery__arrow-forward {
  display: none;
}

.icon-button .image-gallery__arrow-icon {
  color: #fff;
}

.image-gallery__arrow-icon--back {
  transform: rotate(180deg);
}

@media screen and (min-width: 60em) {
  .image-gallery__arrow-back,
  .image-gallery__arrow-forward {
    top: 50%;
    height: 50px;
    display: block;
  }
}

.recordCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0,0,0,.2), 0 3px 4px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.14);
  position: relative;

  .recordSetter {
    position: absolute;
    height: 4rem;
    top: 8rem;
  }

  .recordSetterModal{
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.14);
    margin: 3rem 2.5rem;
    border-radius: 10px;
    background: white;
    position: absolute;
    padding: 1rem;
  }

  .badge {
    position: absolute;
    top: 0;
    right: 1rem;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    flex: 1;

    .recordOwnerInfo {
      color: var(--dark-gray);
      text-transform: uppercase;
      font-size: 0.625rem;
    }

    .idea {
      color: var(--indigo-blue);
      font-size: 1.25rem;
      margin: 0 0 0.5rem 0;
    }

    .description {
      color: var(--dark-gray);
      line-height: 1.8em;
      max-height: 7.2em;
      height: 7.2em;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      font-size: .875rem;
      margin-bottom: 0.5rem;
    }
  }

  .recordButtons {
    display: flex;
    padding-top: 0.5rem;
    flex-direction: row;
    justify-content: space-between;

    .btn {
      font-size: 12px;
      padding: 10px 14px;
    }

    .beatQuizButton:not(:disabled) {
      background: #ffc300;
    }
  }
}

.recordImage {
  height: 200px;
  width: 100%;
  background: rgba(189,189,189,255);
  object-fit: cover;
}

.likeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: .875rem;
  color: var(--dark-gray);

  .icon {
    color: var(--gray);
  }
  .liked {
    color: var(--pure-red)
  }
}

.downWardIcon {
  color: var(--pure-red)
}

.upWardIcon {
  color: var(--pure-green);
}

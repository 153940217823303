.main {
  display: flex;
  flex-direction: column;

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;

    .right {
      display: flex;
    }
  }
}
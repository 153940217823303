.body {
}

.ideasList {
    border: 1px solid var(--gray);
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}

.ideasList ul {
    padding: 0;
    list-style: none;
    max-height: 20rem;
    overflow-y: auto;
}

.ideasList ul li {
    height: 55px;
    border-radius: 5px;
    background: var(--surface);
    padding: 5px 0 5px 10px;
    margin: 5px;
    display: flex;
}

.ideas-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.divider {
    border-right: 1px solid var(--gray);
}

.existed-list {
    width: 100%;
    max-width: 256px;
}

.title {
    font-family: Nunito, serif;
    color: var(--dark-gray);;
}

.padding {
    padding: 5px;
}

.upload-btn {
    width: 100%;
}

.add-icon {
    margin-bottom: 1rem !important;
}

.no-idea {
    width: 100%;
    text-align: center;
    color: gray;
}

.row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 961px) {
    .ideas-container {
        display: flex;
        flex-direction: column;
    }

    .divider {
        border-right: 0;
        border-bottom: 1px solid var(--gray);
    }

    .existed-list {
        max-width: 100%;
    }
}

.accordion {
  width: 100%;
  margin: 0 !important;
}
.accordionTitle {
  width: calc(100% - 50px);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  position: absolute;
  top: 12px;
}
.accordionSummary {
  width: 100%;
  height: 4em;
  max-height: 4em;
  box-sizing: border-box;
}

.record-details__record-button.MuiButtonBase-root {
  padding: 10px 14px;
  font-size: 12px;
  height: 40px;
}

.record-details__category {
  font-size: 10px;
}

.record-details__image-gallery {
  width: 100%;
}

.record-details__content {
  width: 100%;
}

@media screen and (min-width: 60em) {
  .record-details__image-gallery {
    width: 58%;
  }

  .record-details__content {
    width: 40%;
  }
}

.break_record_link {
  color: red;
  line-height: 40px;
  height: 40px;
  padding: 0 20px;
  text-align: center;
  background: rgb(248, 248, 248);
  border-radius: 50px;
}

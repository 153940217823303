.main {
  position: relative;
  padding-top: 2.5rem;
}

.adminButtons {
  position: absolute;
  top: 0;
  right: 0;
}

.top {
  margin-top: 1rem;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .image {
    border-radius: 10px;
    height: 188px;
    width: 188px;
    object-fit: cover;
  }

  .title {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--neo-red);
    margin: 0 0 1rem;
  }

  .didYouKnow {
    background-color: #daedff;
    font-weight: bold;
    padding: 0.8rem;
    border-radius: 10px;
    margin-bottom: 1rem;

    .description {
      margin: 0.8rem 0 0;
      font-weight: normal;
    }
  }

  .link {
    color: var(--indigo-blue);
    font-weight: bold;
  }

  @media screen and (max-width: 50rem) {
    flex-wrap: wrap;
  }
}
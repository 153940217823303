.wrapper {
  display: flex;
  justify-content: right;
  align-items: flex-start;
}

.quantitySelect {
  display: flex;
  justify-content: space-between;
  max-width: 6.6rem;
  align-items: center;

  .iconAdd {
    padding: 8px;
    margin-left: -40px;
  }

  .quantityCount {
    font-size: 20px;
    height: 30px;
    border-radius: 10px;
    -webkit-appearance: none;
    width: 100px;
    text-align: center;
    background: transparent;
    font-weight: 700;
    color: var(--dark-gray);
  }

  .iconRemove {
    padding: 8px;
    margin-right: -40px;
  }
}

.child_form__gender-tooltip {
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

@media screen and (min-width: 400px) {
  .child_form__gender-tooltip {
    width: 300px;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .child_form__gender-tooltip {
    width: 320px;
  }
}

@media screen and (min-width: 60em) {
  .child_form__gender-tooltip {
    width: 420px;
  }
}

.shield {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 2.4rem;

  .level {
    position: absolute;
    width: 100%;
    top: 15%;
    color: white;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -1.869px;
  }
}
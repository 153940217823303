.main {
  display: flex;
  text-transform: none;
  border-radius: 12.5rem;
  cursor: pointer;
  align-items: center;

  .icon {
    color: var(--indigo-blue);
  }

  .label {
    margin: 0;
    font-size: 1.2rem;
    color: var(--indigo-blue);
    font-family: Nunito, serif
  }
}

.splash-avatar {
  animation-name: fade-in-out;
  animation-duration: 3s;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rejectionBox {
    border: 1px solid  var(--gray);
    border-radius: 10px;
    padding: 8px 12px;
}
.submissionTitle {
    font-family: Nunito, serif;
    font-weight: 700;
    font-size: 1.25rem;
    color: #ff5252;
}

.submissionInfo{
    font-family: Nunito, serif;
    font-size: 1rem;
    padding-top: 1rem;
}
.submissionLoadingInfo{
    font-family: Nunito, serif;
    font-size: 1rem;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0.5rem;
}
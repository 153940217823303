.text {
    padding-bottom: 1rem;
    color: #595959;
}
.submitButtonDiv {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 4rem;
    justify-content: flex-start;
    align-items: center;
    .submitButton {
        width: 40%;
    }
    @media screen and (max-width: 961px){
        margin-top: 2rem;
        justify-content: flex-end;
        .submitButton {
            width: 100%;
        }
    }

}
.backButtonDiv {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 2rem;
    justify-content: flex-start;
    align-items: center;
    .backButton {
        width: 40%;
    }
    @media screen and (max-width: 961px){
        justify-content: flex-end;
        .backButton {
            width: 100%;
        }
    }

}
.title {
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
}

.table {
  display: grid;
  position: relative;
  border-top: 2px solid var(--gray);
  align-items: center;
  padding: 2rem 0;
  gap: 2.6rem;
  grid-template-columns: 100%;

  .background_line {
    position: absolute;
    left: 7.7rem;
    height: 75%;
    content: "";
    border-left: 5px solid var(--slate-gray);

    @media only screen and (max-width: 26rem) {
      left: 3.4rem;
    }
  }

  .row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media only screen and (max-width: 26rem) {
    gap: 0.2rem;
  }
}

.left_cell {
  display: flex;
  justify-content: center;
  min-width: 250px;

  .ellipse {
    position: absolute;
  }

  .shield_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;

    .shield {
      top: 0.25rem
    }
  }
  @media only screen and (max-width: 26rem) {
    min-width: 115px;
    transform: scale(0.6);
  }
}

.text {
  font-size: 1.25rem;
  font-weight: 400;
}

.minor_title {
  color: var(--indigo-blue);
  font-size: 1.9rem;
  font-weight: 700;
  margin: 0;

  @media only screen and (max-width: 26rem) {
    font-size: 1.5rem;
  }
}

.record_list {
  display: grid;
  border-top: 2px solid var(--gray);
  align-items: center;
  padding: 2rem 0;
  gap: 2.6rem;
}

.container {
  display: flex;
  position:relative;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;

  .image {
    border-radius: 10px;
    width: 216px;
    height: 188px;
    object-fit: cover;
  }

  .description {
    width: 70%;

    .record_info {
      color: var(--dark-gray);
      line-height: 1.7rem;
      margin: 0;
    }

    .record_title {
      color: var(--indigo-blue);
      font-weight: 700;
      margin: 1rem 0;
    }

    @media only screen and (max-width: 26rem) {
      width: 100%;
    }
  }
}
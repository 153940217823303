.page {
  margin: 40px 0;
  max-width: 74.4rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsoredBy {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.greenButton {
  min-width: 10rem !important;
  padding: 2rem !important;
  border-radius: 100px !important;
  background-color: #0FAB15 !important;
}

.button {
  min-width: 10rem !important;
  padding: 2rem !important;
  border-radius: 100px !important;

  .icon {
    transform: scaleX(-1);
  }
}

.buttonLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  margin-top: 3.5rem;
}

.line {
  height: 0.2rem;
  width: 100%;
  background-color: #4758AB;
}

.sponsor_logo {
  display: flex;
  background-color: white;
  border-radius: 0 0.6rem;
  max-width: 8rem;
  max-height: 5.625rem;
  justify-content: center;

  img {
    object-fit: contain;
  }
}

.description {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.5rem 0;
}

.switchType {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #303F9F;

  .typeDescription {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

.typeIcon {
  color: #303F9F;
  margin-right: 0.2rem;
}

.underlinedLinkButton {
  background-color: unset;
  border: unset;
  color: #DF5346;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 60rem) {
  .header {
    h1 {
      width: 70%;
      padding-bottom: unset !important;
    }

    .sponsoredBy {
      .sponsor_logo {
        max-width: 7rem;
      }
    }

    flex-direction: column;
    align-items: flex-start !important;
  }

  .switchType {
    flex-direction: column;
    align-items: flex-start;

    .description {
      margin: 0.25rem 0 !important;
    }
  }

  .description {
    font-weight: 700 !important;
  }
}

.modal {
  padding: 1.9rem 2% 1.6rem 2%;

  .modalContainer {
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    max-width: 33rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .header {
      min-height: 3rem;
      max-width: 25rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .header1 {
      min-height: 3rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .modalText {
      font-size: 1.25rem;
      max-width: 33rem;
      min-width: 21rem;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .underlinedLinkButton {
        font-size: 1.12rem;
      }

      .startBtn {
        margin-top: 1rem;
        background-color: #0FAB15;
      }

      .nextBtn {
        margin-top: 1rem;
        width: 8rem;
        background-color: var(--neo-red);
      }
    }

    .bottomText {
      font-size: 1rem;
      max-width: 32rem;
    }

    .bottomText1 {
      margin-top: 2rem;
      font-size: 1rem;
      max-width: 32rem;
    }

    .startHeader {
      min-height: 3rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .share {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 1.25rem;

      .shareButtons {
        display: flex;
        flex-direction: row;

        svg {
          fill: var(--neo-red);
        }
      }
    }
  }
}


.recordsSwiper {
  width: 100%;
  margin-top: 2.8rem;

  .slide {
    display: flex;
    flex-direction: column;
    color: white;
    height: 100%;
    background: #4758AB;
    padding: 2% 5% 5%;

    .title {
      font-size: 1.25rem;
      margin: 0.67em 0 0.67em;
    }
    .description {
      font-size: 1rem;
    }
  }
}

.timer {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0;
  gap: 1rem;

  .box {
    color: white;
    background-color: #4758AB;
    padding: 0.8rem 1.2rem;
    align-items: center;
    border-radius: 0.23rem;
    text-align: center;

    .number {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 1.6rem;
      width: 3rem;
      margin: 0;
    }
  }
}
.button.MuiButton-root {
  border-radius: 200px;
  font-family: Nunito, serif;
  font-weight: bold;
  box-shadow: none;
  max-height: 48px;
}

.button--primary.MuiButton-root,
.button--primary.MuiButton-root:hover {
  color: white;
  background-color: var(--neo-red);
}

.button--primary.ghost.MuiButton-root,
.button--primary.ghost.MuiButton-root:hover {
  background-color: unset;
  color: var(--neo-red);
}

.button--primary.outline.MuiButton-root,
.button--primary.outline.MuiButton-root:hover {
  border-color: var(--neo-red);
  border-width: 1px;
  border-style: solid;
}

.button--secondary.MuiButton-root,
.button--secondary.MuiButton-root:hover {
  color: white;
  background-color: var(--indigo-blue);
}

.button--secondary.ghost.MuiButton-root,
.button--secondary.ghost.MuiButton-root:hover {
  background-color: unset;
  color: var(--indigo-blue);
}

.button--secondary.outline.MuiButton-root,
.button--secondary.outline.MuiButton-root:hover {
  border-color: var(--indigo-blue);
  border-width: 1px;
  border-style: solid;
}

.button--tertiary.MuiButton-root {
  color: var(--indigo-blue);
  text-transform: none;
  font-weight: normal;
}

.button--tertiary.outline.MuiButton-root,
.button--tertiary.outline.MuiButton-root:hover {
  border-color: var(--gray);
  border-width: 1px;
  border-style: solid;
}

.button--quaternary.MuiButton-root {
  color: white;
  text-transform: none;
  font-weight: bold;
}

.button--quaternary.outline.MuiButton-root,
.button--quaternary.outline.MuiButton-root:hover {
  border-color: var(--gray);
  border-width: 1px;
  border-style: solid;
}

.button--quaternary.ghost.MuiButton-root,
.button--quaternary.ghost.MuiButton-root:hover {
  background-color: unset;
  border-color: var(--dark-gray);
  color: var(--dark-gray);
}

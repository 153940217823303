.bordered-container {
  border: 1px solid var(--gray);
  border-radius: 10px;
  padding: 70px 90px;
  margin: 40px 0;
}

@media screen and (max-width: 60em) {
  .bordered-container {
    padding: 10px;
  }
}

.body {
  position: relative;
  background: rgba(160, 160, 160, 0.13);
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  &.animation-body {
  }

  .text {
    text-align: center;
    width: 100%;
  }

  .text.loading {
    color: gray;
  }

  .margin {
    margin-top: 15px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  &.animation-body::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: MuiSkeleton-keyframes-wave 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(
                    90deg,
                    transparent,
                    rgba(155, 155, 155, 0.08),
                    transparent
    );
  }

  @keyframes MuiSkeleton-keyframes-wave {
    0% {
      transform: translateX(-100%);
    }
    60% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

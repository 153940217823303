.header {
  color: var(--neo-red);
  align-self: center;
}

.container {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  .text {
    font-weight: 600;
    letter-spacing: 0.02rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 27rem;
  }

  .width31 {
    max-width: 31rem;
  }
}

.paymentComplete {
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    color: Green;
    align-self: center;
  }

  .checkCircle {
    color: green;
    font-size: 5rem;
    margin-bottom: 1rem
  }
}
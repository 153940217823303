html, body {
  margin: 0;
  box-sizing: border-box;
  font-family: Nunito, 'OpenSans-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 95%;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  /* background-image: url("../public/assets/snowflakes.png");
  background-size: 20%; */
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: inherit;
  font-family: Nunito, serif;
}

.ppl-cont {
  width: 100%;
}

.dp-fullWidth {
  width: 100%;
}

figure {
  margin: 0;
}

.nslt-text .MuiInputBase-input {
  padding: 0 0 0 10px !important;
}

.MuiPopover-root .MuiPopover-paper.MuiPaper-rounded {
  border-radius: 10px !important;
}

.MuiInputBase-input::file-selector-button {
  font-size: .875rem;
  color: white;
  background-color: var(--neo-red);
  border-radius: 200px;
  margin: 0.125rem 0.25rem 0.125rem 0;
  padding: 0 0.5rem;
  border: unset;
}

.Mui-disabled::file-selector-button {
  background-color: var(--gray) !important;
}

.pac-container {
  margin-top: 2px;
  border-radius: 10px;
}

.title {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 2rem 0 1rem;
}

.loader {
  margin-top: 2rem;
  left: calc(50% - 30px);
}

.tableHead {
  .cell {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: Nunito, serif;
    padding: 1rem 0;

    :global(.MuiTableSortLabel-icon) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 40rem) {
      font-size: 1.4rem;
      text-align: center;
    }
  }
}

.tableRow {
  .cell {
    font-family: Nunito, serif;
    padding: 0.4rem;
  }

  .bold {
    font-weight: 700;
  }
}

.tableRow:nth-of-type(odd) {
    .cell {
      color: white;
    }
}

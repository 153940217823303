.input {
  label, input {
    font-family: Nunito, serif;
  }
  margin-bottom: 1rem !important;
  
  :global {
    .MuiListItemText-root {
      margin: 0;
      height: 23px;
      line-height: 23px;
    }

    .MuiFilledInput-input {
      height: auto;
    }

    .MuiFormLabel-asterisk {
      color: red;
    }

    .MuiInputBase-root {
      background-color: var(--surface);
      border-radius: 10px;
      border: 1.2px solid #ffffff;
      overflow: hidden;

      &:before {
        display: none;
      }

      &:after {
        display: none;
      }


      &:hover {
        &:not(.Mui-disabled) {
          background-color: var(--dark-surface);
        }
      }

      &.Mui-focused {
        border: 1.2px solid var(--indigo-blue);
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.Mui-error {
        border-color: var(--pure-red);
        background-color: rgb(255, 242, 242);
      }
    }

    .MuiFormLabel-root {
      top: 3px;
      &.Mui-focused {
        color: var(--indigo-blue);
      }

      &.Mui-error {
        color: var(--pure-red);
      }
    }
  }
}

.pinInput {
  display: flex;
  flex-direction: column;

  .inputs {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .digit {
      max-width: 4rem;

      :global {

        .MuiFilledInput-input {
          padding: 12px 12px 12px 12px;
        }
        .MuiInputBase-input {
          text-align: center;
        }
      }
    }
  }
}

.textArea {
  max-width: 100%;
  width: 100%;
  min-height: 58px;
  resize: vertical;
  border: 1.2px solid var(--surface);
  border-radius: 4px;
  padding: 12px;
  background-color: var(--surface);
  outline: none;

  &:hover {
    background-color: var(--dark-surface);;
  }

  &:focus-visible {
    border: 1.2px solid var(--indigo-blue);
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-family: Nunito, serif;
    color: var(--dark-gray);
    font-size: 1rem;
  }
}

.picker {
  width: 100%;
}

.titlePage {
  text-align: center;
}

.description {
  text-align: center;
}

.title {
  color: white;
  background: #4758AB;
  font-size: 1.25rem;
  padding-top: 1rem;
  margin-bottom: 0;
  text-align: center;
}
.swiperWrapper {
  background-color: #4758AB;
}

.slide {
  display: flex;
  flex-direction: column;
  color: white;
  background: #4758AB;
  padding: 2% 5% 5%;

    
  .description_swiper {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    }
  }
.linkButton {
  width: 100%;
  text-align: center;
}
.linkButton button {
  width: 20%;
  @media screen and (max-width: 961px) {
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.totalInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  .cardInfo{
    text-align: center;
    flex-basis: 30%;
    flex-grow: 0;     
    flex-shrink: 0;
  }

  .recordsFactsNumber {
    color: #f2453d;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

}

.errorMessageDisplay {
  width:100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #ff5252;
}

.editMessage {
  color: #f2453d;
  text-align: center;
  font-weight: 700;
}
.swiper_slide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  width: 100%;
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  flex-wrap: wrap;

  .image {
    border-radius: 10px;
    height: 188px;
    object-fit: cover;
  }

  .record_title {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--indigo-blue);
    margin: 0 0 1rem;
  }

  .information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--indigo-blue);
    line-height: 1.5rem;

    .name {
        margin: 0;
        font-weight: 800;
      }

      .record_info {
        margin: 0;
      }
    }

    .link {
      white-space: nowrap;
      font-weight: 800;
    }
  }

.arrow {
  color: var(--indigo-blue);
}
.textInfo {
   // color: #757575;
    margin-bottom: 2rem;
}

.textBold {
    font-weight: 700;
}
.buttonsDiv{
    margin-top: 2rem;
    padding-left: 3rem;
    display: flex;
    flex: 0 0;
    gap: 1.6rem;
    @media screen and (max-width: 60rem) {
        padding-left: 0;
    }
}

.btnActive {
    width:30%;

    &:global(.button--secondary.MuiButton-root) {
        border-radius: 0.6rem;
        background-color: darkblue;
        color: white;
    }
  }
  .btnNotActive {
    width:30%;
    &:global(.button--secondary.MuiButton-root) {
        border-radius: 0.6rem;
        background-color: lightgrey;
        color: grey;
    }
  }

.miniHeader {
    color: darkblue;
}
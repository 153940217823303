.thumbnail__media {
  position: relative;
  width: 30%;
  max-height: 10vh;
  border-radius: 15px;
  margin-right: 5%;
  overflow: hidden;
}

.thumbnail__media:last-child {
  margin-right: 0;
}

.thumbnail__media--selected:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.8;
  content: '';
}

.thumbnail__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .thumbnail__media {
    margin-right: 16px;
    border-radius: 15px;
    max-height: 80px;
    max-width: 150px;
  }
}

.modal {
  padding: 1.9rem 1.3% 1.6rem 1.3%;
  min-width: 22rem;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .header {
    color: #3342A0;
    font-weight: 800;
    font-size: 1.9rem;
    text-align: center;
    margin: 0;
  }

  .text {
    color: black;
    font-size: 1.25rem;
    text-align: center;
    margin: 1.5rem 0;
  }

  .button {
    width: 11rem;
    border-radius: 100px;
    font-size: 1rem;
  }

}
.main {
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    font-family: Nunito, serif;
    color: var(--dark-gray);
    font-size: 1rem;
  }
}

.colored {
    color: var(--indigo-blue);
}

.margin-inf-rw {
    margin-top: 15px
}

.buttons-idea-gen {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

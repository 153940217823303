.shadow-modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 280px;
  min-height: 250px;
}

.shadow-modal.large {
  width: 480px;
}

.shadow-modal.noSize {
  width: unset;
}

.shadow-modal.shadow {
  box-shadow: 0 5px 5px #00000055;
}

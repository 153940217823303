.mediaUpload {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
  position: relative;
}

.uploadProgress {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.7);
    animation: blurring 3s infinite;
    border-radius: 5px;
  }

  .text {
    position: absolute;
    color: white;
    font-size: 2rem;
    filter: blur(0.6px);
    text-align: center;
  }
}

@keyframes blurring {
  0% {
    filter: blur(4px);
  }
  50% {
    filter: blur(7px);
  }
  100% {
    filter: blur(4px);
  }
}

.dash_ul {
  list-style-type:'- ';
  padding: 0px 0px 0px 10px;
  font-size: .75rem;	
  margin-top: var(--spacing-extra-small);
  margin-bottom: var(--spacing-extra-small);
  color: var(--dark-gray)
}

.dash_ul > li {
  margin: 5px 0px 5px 0px;
}

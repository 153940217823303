.page {
  margin: 40px 0;
  max-width: 74.4rem;
}

.title {
  padding-bottom: 1rem;
}

.description {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.control_panel {
  display: flex;
  flex-direction: row;
  gap: 10rem;
  align-items: center;
  margin: 2.5rem 0;
  flex-wrap: wrap;

  @media screen and (max-width: 60rem) {
    gap: 2rem;
  }

  .search {
    margin: 0;
    max-width: 34.8rem;
  }
}

.dialogContent {
  padding: 2rem !important;
}

.typeModal {
  .pickTypeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      padding-bottom: 0;
    }

    .description {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: black;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    color: #3342A0;

    .red {
      color: #FF5252;
    }

    .button {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 3rem 1rem;

      text-align: center;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.85rem;

      .buttonTitle {
        font-weight: 700;
      }

      .typeIcon {
        height: 30%;
        width: 30%;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 60rem) {
  .dialogContent {
    padding: 0.5rem 0.5rem 1rem !important;
  }
  .pickTypeHeader {
    padding-top: 1rem;
    .title {
      font-size: 1.25rem;
    }

    .description {
      font-size: 0.875rem !important;
      margin: 0.5rem 0 1rem !important;
    }
  }
  .buttons {
    gap: 0.4rem !important;

    .button {
      margin-top: unset !important;
      padding: 1.25rem 0.5rem !important;
      font-size: 0.875rem !important;
      line-height: 1rem !important;
    }
  }
}

.question {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  gap: 1rem;

  .questionImageDiv {
    width: 60%;
    .questionImage {
      width: 100%;
      background: #f8f8f8;
      border-radius: 0.2rem;
      overflow: auto;
  
      img {
        object-fit: contain;
        align-self: self-start;
      }
    }
    a {
      color:#3342A0;
      text-decoration: underline;
    }
  }

  .answersBlock {
    display: flex;
    flex-direction: column;
    width: 40%;

    .answers {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      margin-bottom: 1.8rem;

      .answer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 3.5rem;
        border-radius: 0.2rem;
        overflow: auto;

        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        cursor: pointer;

        .letter {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.5rem;
          margin-right: 1.25rem;
          height: 100%;
        }

        .container {
          display: flex;
          width: 75%;
          justify-content: space-between;
        }
      }

      .inProgress {
        background: #F0F0F0;
        .letter {
          background: #E3E3E3;
        }
      }

      .wrong {
        background: #F1C2C0;
        .letter {
          background: #C62921;
        }
      }

      .correct {
        background: #B7E4B9;
        .letter {
          background: #0FAB15;
        }
      }
    }

    .questionCounters1 {
      display: none;
    }

    .questionCounters2 {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      .counterRemaining {
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 60rem) {
  .question {
    flex-direction: column !important;
    .questionImageDiv {
      width: 100% !important;
    .questionImage {
      width: 100% !important;
    }}

    .answersBlock {
      width: 100% !important;

      .questionCounters1 {
        display: flex !important;
        flex-direction: row;
        gap: 1.5rem;
        margin-bottom: 1rem;
      }
      .questionCounters2 {
        display: none !important;
      }
    }
  }
}

.suggestQuiz {
  .header {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: max-content;

    .icon {
      margin-left: 1rem;
      margin-right: 1rem;
      transform: scale(1.8);
    }
  }
  .suggestionForm {
   display: flex;
    flex-direction: column;
    width: 100%;
  }
  .inputEmail {
    max-width: 50%;
  }

  .button {
    align-self: flex-end;
  }
}

@media screen and (max-width: 60rem) {
  .suggestQuiz {
    .inputEmail {
      max-width: 100% !important;
    }
  }
}

.main {
  display: flex;
  position: relative;
  justify-content: center;

  .select {
    padding: 0 0.8rem;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    width: 21.5rem;
    border-radius: 15.51px;
    background: #3342A0;
    color: white;
    font-family: Nunito, serif;
    border: none;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(285px,1fr));
  grid-template-rows: repeat(auto-fill,minmax(250px,1fr));
  gap: 25px;

  .card {
    display: flex;
    flex-direction:column;
    background-color: white;
    position: relative;
    overflow: hidden;

    .image_container {
      height: 200px;

      .image {
        height: 100%;
      }
    }

    .sponsor_logo {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      background-color: white;
      border-radius: 0 0.6rem;
      width: 8rem;
      height: 2.8rem;
      justify-content: center;

      img {
        object-fit: contain;
      }
    }

    .cardDescription {
      padding: 0.8rem;

      .title {
        font-size: 1rem;
        font-weight: 700;
        line-height: normal;
        margin: 0;
        padding-bottom: 0.3rem;
      }

      .description {
        color: var(--dark-gray);
        font-size: 0.8rem;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  font-family: Nunito, serif;
  color: var(--indigo-blue);
  cursor: pointer;

  h3 {
    margin: 0;
    font-size: 1.2rem;
  }
}

.body {
  padding: 1rem;

  .columns {
    display: flex;
    flex-direction: row;

    & > div {
      padding: 0.5rem;
    }
  }

  .buttons {
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: space-between;

    & > * {
      margin: 0.5rem;
    }
  }
}

.sidebar {
  width: 250px;
  border-right: 1px solid var(--dark-surface);
  box-shadow: 3px 0 10px 1px var(--dark-surface);

  .icon {
    color: var(--indigo-blue);

    &.selected {
      color: white;
    }
  }

  .listItem {

    &:global(.Mui-selected) {
      background: var(--indigo-blue);
      color: white;
    }
  }
}
.page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

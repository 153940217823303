.select {
  width: 100%;
}

.export {
  margin-top: -1rem!important;
  width: 30rem;
}

.red {
  background-color: #ffe8e6;
}

.green {
  background-color: #98fb98;
}

.searchRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.tableContainer {
  margin-bottom: 1rem;
  flex: 1;
}

.center {
  display: flex;
  justify-content: center;
}

.reviewModalContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  min-width: 50rem;
  justify-content: center;

  .columnLeft {
    display: flex;
    flex: 1;
    padding: 0 1rem 0 1rem;
  }

  .columnRight {
    padding: 0 1rem 0 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;

    .row {
      display: flex;
      padding-left: 1rem;
      align-items: center;
      width: 100%;
      min-height: 2.5rem;

      .fieldName {
        width: 50%;
        font-weight: bold;
      }
      .fieldData {

      }

      .reject {
        font-weight: bold;
        color: var(--pure-red);
      }
      .approved {
        font-weight: bold;
        color: var(--pure-green);
      }
    }
    .row:nth-of-type(odd) {
      background-color: var(--surface);
    }

    .input {
      margin-top: 1rem;
      padding-bottom: 1.5rem;
    }
  }
}

.loader {
  top: 50%;
  left: calc(50% - 30px);
  z-index: 999;
}

.closeBtn {
  display: flex !important;
  align-self: flex-end;
  position: absolute !important;
  right: 1rem;
  top: 1rem;
}

.arrowFwd {
  display: flex !important;
  align-self: flex-end;
  position: absolute !important;
  right: 1rem;
  top: 50%;
}

.arrowBack {
  display: flex !important;
  align-self: flex-start;
  position: absolute !important;
  left: 1rem;
  top: 50%;
}

.buttons {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  .green {
    color: var(--pure-green) !important;
  }
}


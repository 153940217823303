.checkbox {
  margin-bottom: 1rem;

  h1 {
    font-weight: normal;
  }
}

.namesDiv {
  display: flex;
  flex-direction: row;

  .firstName {
    margin-right: 5px;
    flex: auto;
  }
  
  .lastName {
    margin-left: 5px;
    flex: auto;
  }
}

.buttonsDiv {
  display: flex;
  height: 100%;
  margin-top: 64px;
  margin-bottom: 64px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .nextButton {
    width: 16rem;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  .alreadyHaveButton {
    width: 16rem;
    margin-top: 2rem;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
}

.page {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  top: 50%;
  left: calc(50% - 30px);
  z-index: 999;
}

.imageOrVideo {
  object-fit: contain;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 54rem;
  font-size: 1.125rem;
}

.titleBox {
  align-items: center;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .checkboxText {
    margin: 0.7rem 0.5rem;
  }

  .checkmark {
    display: flex;
    align-items: center;
  }
}

.blueLink {
  text-decoration: underline;
  color: blue;
}

.redLink {
  text-decoration: underline;
  color: red;
}

.columnBox {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: underline;
  }

  &.padding {
    padding: 2rem 0 1rem;
  }
}

.title {
  padding: 0.8rem 0;
  font-size: 2.5rem;
}

.timerDescription {
  font-weight: bold;
  padding: 1rem 0 1rem 0;
  font-size: 1.25rem;
}

.submissionText {
  font-size: 1.9rem;
  font-weight: 700;
  padding-bottom: 2rem;
}

.timerBox {
  padding: 0 0 1.8rem 0;
}

.sponsorTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 2.9rem;
}

.sponsors {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  padding: 1rem 1rem;
  justify-content: center;
}

.rowBox {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.picker {
  display: flex;
  width: 100%;
}

.modal {
  padding: 1.9rem 2% 1.6rem 2%;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  .header {
    min-height: 3rem;
    font-weight: bold;
    font-size: 1.9rem;
    color: #3342a0;
  }

  .modalText {
    font-size: 1.25rem;
    max-width: 29rem;
    min-width: 21rem;
  }

  .bottomText {
    font-size: 1rem;
    max-width: 32rem;
  }

  .closeBtn {
    width: fit-content;
    align-self: center;
    padding: 25.56px 48.84px;
  }
}

.buttonBox {
  display: flex;
}

.timer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.25rem 0;
  gap: 1rem;

  .box {
    color: var(--neo-red);
    padding: 0.8rem 1.2rem;
    align-items: center;
    border: 1px solid var(--neo-red);
    border-radius: 0.25rem;
    text-align: center;

    .number {
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-size: 1.6rem;
      width: 3rem;
      margin: 0;
    }
  }
}

@media screen and (max-width: 416px) {
  .timer {
    width: 295px;
  }
}
@media screen and (max-width: 215px) {
  .timer {
    width: 100%;
    max-width: 151px;
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
  }
  .columnLeft {
    margin-right: unset;
    max-width: 100%;
  }

  .columnRight {
    margin: unset;
  }
}
.wrapper {
  position: relative;
  img {
    border-radius: 5px;
    overflow: hidden;
  }

  .buttons {
    position: absolute;
    top: 5px;
    right: 5px;

    svg {
      fill: var(--pure-red);
    }
    .button {
      width: 60px;
      height: 60px;
    }
    .customIcon  {
      font-size: 36px;
    }
  }
}

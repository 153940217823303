.avatar--small.MuiAvatar-root {
  width: 20px;
  height: 20px;
}

.avatar--medium.MuiAvatar-root {
  width: 24px;
  height: 24px;
}

.avatar--xlarge.MuiAvatar-root {
  width: 120px;
  height: 120px;
}

.navigation {
  margin-top: 70px;
  transition: left 0.4s ease;
}

.navigation--collapsed {
  left: -18rem;
  transition: left 0.4s ease;
}

.navigation-icon.icon-button {
  position: absolute;
}

@media only screen and (min-width: 60em) {
  .navigation {
    color: var(--dark-gray);
    margin-top: 0;
  }

  .navigation--collapsed {
    left: unset;
  }

  .navigation-icon.MuiIconButton-root {
    display: none;
  }
}

.medias {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  gap: 0.5rem;
}

.mediaItem {
  width: 21.25rem;
  height: 12.5rem;
  border-radius: 0.5rem;
}

.addNewItem {
  display: flex;
  width: 21.25rem;
  height: 12.5rem;
  border-radius: 0.5rem;
  background-color: #F2F2F2;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }

  .icon {
    font-size: 2.5rem;
    color: #001b44;
  }
}

.ideasList {
  padding: 5px;
  margin-top: 5px;
}

.ideasList ul {
  padding: 0;
  list-style: none;
  max-height: 20rem;
  overflow-y: auto;
}

.ideasList ul li {
  height: 55px;
  border-radius: 5px;
  background: var(--surface);
  padding: 5px 0 5px 10px;
  margin: 5px;
  display: flex;
}

.ideas_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divider {
  border-right: 1px solid var(--gray);
  margin: 0px 5px 0px 15px;
}

.existed_list {
  width: 100%;
}

.title {
  font-family: Nunito, serif;
  color: var(--dark-gray);
  margin-left: 10px;
}

.no_idea {
  width: 100%;
  text-align: center;
  color: gray;
}

.row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.spanMoney {
  color: red;
}
.btnDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.btn {
  width: 40%;
}

@media screen and (max-width: 961px) {
  .ideas_container {
    display: flex;
    flex-direction: column;
  }

  .divider {
    border-right: 0;
    border-bottom: 1px solid var(--gray);
    margin: 10px 0px 5px 0px;
  }

  .existed_list {
    max-width: 100%;
  }
}

.card {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.card--list-view {
  display: grid;
  box-shadow: none;
}

.card--list-view.card-record {
  grid-template-columns: 90px 1fr 48px;
}

.card--list-view.card-submission {
  grid-template-columns: 90px 1fr;
}

.card__image {
  height: 200px;
}

.level_badge {
  position: absolute;
  top: 0;
  right: 1rem;
}

.level_badge.small{
  transform: scale(0.4);
  position: absolute;
  top: -1.9rem;
  right: -1.25rem;
}

.card--list-view .card__image {
  border-radius: 10px;
  height: 80px;
  margin: auto;
  overflow: hidden;
}

.card__category {
  font-size: 10px;
}

.card__title {
  font-size: 16px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card__description {
  text-overflow: ellipsis;
  word-wrap: break-word;
  max-height: calc(3 * 1.8em);
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card--list-view .card__description {
  max-height: 1.8em;
  -webkit-line-clamp: 1;
}

.card + .card {
  margin-top: 1.5em;
}

.card__container {
  font-size: 1rem;
}

.card--list-view .card__container {
  border-bottom: 1px solid #ebebeb;
  display: inline-grid;
  justify-content: start;
}

.card__container.small {
  font-size: 0.5rem;
  flex: 4;
}

.card__separator {
  border-top: 1px solid #eaeaea;
}

.card__media {
  object-fit: cover;
  width: 100%;
}

.card__media.small {
  flex: 1;
  width: 20vw;
  height: 100%;
  min-height: 0;
}

.card__buttons.small {
  flex: 1;
}

.card--list-view .card__buttons {
  border-bottom: 1px solid #ebebeb;
}

.card__break-record-button.MuiButtonBase-root {
  padding: 10px 14px;
  font-size: 12px;
}

.card__beat-quiz-button.MuiButtonBase-root {
  padding: 10px 14px;
  font-size: 12px;
  background: #ffc300;
}

.card__beat-quiz-button.MuiButtonBase-root:hover {
  background: #ffc300;
}

.card__shop-button.MuiButtonBase-root {
  padding: 6px 16px;
  font-size: 12px;
}

.card__record-button-container > * {
  font-size: 10px;
}

.card__price-tag {
  margin: 0;
  height: 100%;
  padding-top: 5px;
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .card {
    grid-template-columns: 147px 1fr 60px;
  }

  .card + .card {
    margin-top: 0;
  }

  .card + .card.small {
    margin-top: 1.5em;
  }

  .card__title {
    font-size: 20px;
  }

  .card__media.small {
    width: 15vw;
    height: 100%;
    min-height: 0;
  }

  .card__container.small {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 60em) {
  .card {
    grid-template-columns: 147px 1fr 60px;
  }

  .card + .card {
    margin-top: 0;
  }

  .card + .card.small {
    margin-top: 1.5em;
  }

  .card__title {
    font-size: 20px;
  }

  .card__media.small {
    width: 10vw;
    height: 100%;
    min-height: 0;
  }

  .card__container {
    font-size: 1.25rem;
  }

  .card__container.small {
    font-size: 1rem;
  }
}

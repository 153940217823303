.screen {
  max-width: 90%;
}

.buttons {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  gap: 5px;
}

.plusButton {
  position: absolute;
  bottom: 8.5rem;
  right: 3.5rem;

  .iconButton {
    width: 70px;
    height: 70px;
    background-color: rgba(214, 214, 214, 0.5);

    .addIcon {
      font-size: 45px;
    }
  }
}

.exportButton {
  position: absolute;
  bottom: 13.5rem;
  right: 3.5rem;

  .iconButton {
    width: 70px;
    height: 70px;
    background-color: rgba(214, 214, 214, 0.5);

    .downloadIcon {
      font-size: 45px;
    }
  }
}

.buttonsDiv{
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.errorText {
    color: #ff5252;
}

.CKadminEdit {
    a {
      color: blue;
      text-decoration: underline;
    }
    blockquote {
      border-left: 5px solid #303f9f;
      font-style: italic;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    figcaption {
      background-color: white !important; 
    }
  }

  .document_editor_toolbar {
    position: sticky;
    top:0px;
    z-index: 5;
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }
.headButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 40em) {
    justify-content: space-between;
    width: 100%;

    .button240 {
      width: 100%;
    }
  }
}

.brokenRecordRow {
  align-items: flex-start;
}

.button240 {
  width: 240px;
}

.reviewButtons {
  margin: 1rem 0;
}

.ambassadorIcon {
  margin-left: 10px;
  color: orange
}

.icon {
  color: var(--neo-red);
  margin-right: 0.25rem
}

.category {
  input {
    text-transform: capitalize;
  }
}

.wrapper {
  position: relative;
  img {
    border-radius: 5px;
    overflow: hidden;
  }

  .buttons {
    position: absolute;
    top: 5px;
    right: 5px;

    svg {
      fill: var(--pure-red);
    }

    .button {
    }
  }
}

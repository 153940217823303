.pin {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .pinInput {
    width: 55px;

    :global(.MuiFormHelperText-root) {
      margin-left: 5px;
      white-space: nowrap;
    }

    input {
      text-align: center;
      padding-bottom: 15px;
      padding-top: 15px;
    }
  }
}

.btn {
  &:global(.MuiButtonBase-root) {
    padding: 6px 16px;
    font-size: 12px;
  }
}

.btnDisabled {
  &:global(.button--primary.MuiButtonBase-root) {
    background-color: lightgrey;
    color: grey;
  }
}

.price_tag {
  margin: 0;
  height: 100%;
  padding-top: 5px;
  font-weight: 700;
}

.container {
  max-height: 100px;
}

.constainerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rowDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
}

.btnDiv {
  height: 42px;
}

.priceColorDis {
  color: #bdbdbd;
}

.priceColor {
  color: #000;
}

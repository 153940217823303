.logo {
  display: block;
  width: 50px;
  height: 50px;
  background-image: url('./icon-192x192.png');
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (min-width: 60em) {
  .logo {
    width: 60px;
    height: 60px;
  }
}

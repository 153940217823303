.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  //height: 100%;
}

.containerRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 1.3rem;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px
}

.contestTable {
  display: flex;
  flex-direction: column;
}

.contestLinkBox {
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0;
  padding: 0 1rem;
  border: 1px solid #ccced1;
  border-radius: 10px;
}

.inputRow {
  display: flex;
  flex-direction: row;
}

.dateTime {
  display: flex;
  flex-direction: row;
}

.submitBtn {
  width: fit-content;
  align-self: center;
}

.sponsorSelectItem {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .img {
    height: 2rem;
  }
}

.eventSponsors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.whitelistBox {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0 0;
  padding: 0.45rem 1rem;
  border: 1px solid #ccced1;
  border-radius: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  padding-left: 5.8rem;
}

.socialLinks {
  display: flex;
  align-items: center;
  flex-direction: row;
  list-style-type: none;
  padding-right: 1rem;
  gap: 0.5rem;

  .icon {
    width: 2.25rem;
    height: 2.25rem;
    object-fit: contain;
  }
}

.imageContainer, .imageContainer_FEATURE_513 {
  position: absolute;
  right: 25px;
  bottom: 77px;
  z-index: 998;

  .racoon {
    height: 100px;
  }
}

.headContainer, .headContainer_FEATURE_513 {
  position: absolute;
  padding-right: 40px;
  padding-bottom: 20px;
  right: 38px;
  bottom: 144px;
  z-index: 999;

  .racoon {
    height: 73px;
  }
}
@media screen and (min-width: 1550px) {
  .imageContainer_FEATURE_513 {
    bottom: 65px;
  }
  .headContainer_FEATURE_513 {
    padding-right: 25px;
    padding-bottom: 30px;
    right: 50px;
    bottom: 120px;
  }
}

@media screen and (min-width: 1432px) {
  .imageContainer {
    bottom: 65px;
  }
  .headContainer {
    padding-right: 25px;
    padding-bottom: 30px;
    right: 50px;
    bottom: 120px;
  }
}
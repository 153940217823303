.editor {
  max-width: 400px;
}

.errorColor {
  color: var(--pure-red);
}

.error {
  color: var(--pure-red);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px 14px 0;
}

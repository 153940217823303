.popUpBox{
    border-radius: 10px;
    background-color: beige;
    bottom: 0;
    left:0;
    width: 300px;
    
    .header {
        padding: 1rem;
        padding-bottom: 0.5rem;
        font-size: 20px;
        color: #3342A0;
        font-weight: 800;
        margin: 0;
        display: flex;
        justify-content: space-between;
        .closeBtn {
            aspect-ratio: 1/1;
        }
    }
    
    .text {
        margin: 0;
        padding: 1rem;
        padding-top: 0.5rem;
        color: black;
        .link {
            text-decoration: underline;
            color: blue;
        }
    }
}

.title {
  color: var(--neo-red);
  align-self: center;
}

.text {
  min-width: 13rem;
  max-width: 38rem;
  margin-bottom: 0.83em;
  line-height: 2rem;
}

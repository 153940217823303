.buttons {
  display: flex;
  gap: 1.6rem;
  margin-top: 1.5rem;
  height: 2.6rem;

  .btn {
    padding: 0 2.5rem;
    font-size: 0.9rem;
    border-radius: 0.6rem;
    text-transform: none;

    &:global(.button--secondary.ghost.MuiButton-root) {
      background-color: var(--light-gray);
      color: var(--dark-gray);
    }
  }
}

.main_title {
  padding-bottom: 1.2rem;
}

.description {
  font-family: Nunito, serif;
  max-width: 48rem;
  font-size: 1.125rem;
  padding-bottom: 2rem;
  line-height: normal;
}

.program_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .image {
      width: 14rem;
      height: 14rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .description {
      font-size: 1rem;
      max-width: 15rem;

      @media screen and (max-width: 60rem) {
        max-width: 100%;
      }
    }
  }
}

.level_item {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  .container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;

    img {
      object-fit: cover;
      width: 370px;
      height: 212px;
      border-radius: 10px;
    }

    .title {
      margin: 0.2rem 0 0.3rem;
    }

    .description {
      font-size: 1rem;
      max-width: 29rem;
    }
  }
}

.buttonBox {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .button {
    height: 4.3rem;
    padding: 1.6rem 3rem;
    font-size: 1rem;
  }
}

.modal {
  padding: 1.9rem 3% 1.6rem 3%;

  .modalContainer {
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    max-width: 30rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .header {
      min-height: 3rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .modalText {
      font-size: 1.25rem;
      max-width: 34rem;
      min-width: 21rem;
    }
  }
}
.closeBtn {
  display: flex;
  position: absolute !important;
  right: 1rem;
  top: 1rem;
}

.people__name {
  width: 100%;
}

.people__email {
  width: 100%;
}

@media screen and (min-width: 60em) {
  .people__name {
    width: 50%;
    margin-right: 5px;
  }

  .people__email {
    width: 50%;
    margin-left: 5px;
  }
}

.switch-profile__menu .MuiPaper-root {
  background-color: var(--surface);
  border-radius: 9px;
  box-shadow: 8px 8px 5px 0 rgba(0, 0, 0, 0.25);
}

.switch-profile__menu-item.MuiListItem-root {
  padding: 0;
  margin: 0;
}

.switch-profile__menu-item .MuiListItemIcon-root,
.switch-profile__menu-item .MuiListItemAvatar-root,
.switch-profile__menu-item .MuiListItemText-root {
  min-width: 16px;
  font-family: Nunito, serif;
}

.switch-profile__menu-item-text.MuiListItemText-root {
  min-width: 140px;
}

.switch-profile__menu-divider.MuiDivider-root {
  height: 3px;
  margin: 0 10px;
  background-color: var(--dark-surface);
}

.switch-profile .avatar,
.switch-profile__menu .avatar {
  width: 24px;
  height: 24px;
}

@media only screen and (min-width: 60em) {
  .switch-profile .avatar {
    width: 40px;
    height: 40px;
  }

  .switch-profile__menu .avatar {
    width: 32px;
    height: 32px;
  }
}

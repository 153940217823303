.outlinedContainer {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  position: relative;
  padding: 12px;
  margin-top: 1rem;

  &.error {
    border-color: var(--pure-red);
  }

  .label {
    font-size: 0.8rem;
    position: absolute;
    background: white;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 0.2rem;
    top: -8px;
    z-index: 1;

    &.error {
      color: var(--pure-red);
    }
  }
}

.enter_your_pin__loader {
  top: 50%;
  left: calc(50% - 30px);
  z-index: 999;
}

.enter_your_pin__input .MuiInputBase-input {
  padding-bottom: 15px;
  padding-top: 15px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin-bottom: 20px;
} 

.enter_your_pin {
  padding:var(--spacing-large);
}

@media screen and (min-width: 60em) {
  .enter_your_pin {
    min-width: 600px;
  }
}

.send_reset{
  width:100%;
  text-align: end;
}

@media screen and (max-width: 60em) {
  .send_reset {
    text-align: start;
  }
}

.send_reset_link {
  text-align: right;
  color: blue;
  cursor: pointer;
  background: transparent;
  border: none;
  width: fit-content;
  align-self: flex-end;
  font-size: 15px;
  padding-right: 0;

}

.send-reset-link:focus {
  outline: none;
}

.pinInput {
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.buttonsDiv {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: var(--spacing-medium);

  .buttonChoice {
    width: 8rem;
  }

  @media screen and (max-width: 961px) {
    flex-direction: column;

    .buttonChoice {
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    }
}

.yourPinSpan {
  font-size: .875rem;
}
.tooltip {
  position: relative;
}

.tooltip__content {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  visibility: hidden;
  top: -50%;
  right: 105%;
}
.tooltip__content.visible {
  visibility: visible;
}

@media screen and (min-width: 60em) {
  .tooltip__content.right-desktop {
    left: 105%;
    right: auto;
  }
}

.medias {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  gap: 0.5rem;
}

.mediaItem {
  width: 21.25rem;
  height: 12.5rem;
  position: relative;
  border-radius: 0.5rem;
  background-color: #F2F2F2;
  img {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0.5rem;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--neo-red);
    }

    .progress {
      position: absolute;
      top: 33%;
      line-height: 4rem;
      width: 4.5rem;
      left: 40%;
      color: white;
      font-family: Nunito, serif;
      font-weight: 800;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    background-color: #F2F2F2;
  }
}

.addNewItem {
  display: flex;
  width: 21.25rem;
  height: 12.5rem;
  border-radius: 0.5rem;
  background-color: #F2F2F2;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }

  .icon {
    font-size: 2.5rem;
    color: #001b44;
  }
}

.page {
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  .content {
    width: 100%;
    align-self: center;
    padding: 0 4rem;
    max-width: 74.5rem;

    @media only screen and (max-width: 26rem) {
      padding: 0 1rem 1rem 1rem;
    }
  }
}


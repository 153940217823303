.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.title {
  padding: 0.5rem 0;
  font-size: 2.5rem;
  font-weight: 700;

  @media screen and (max-width: 60rem) {
    font-size: 1.5rem;
  }
}

.description {
  font-size: 1rem;
  font-weight: 400;

  @media screen and (max-width: 60rem) {
    font-size: 1rem;
  }
}

.eventDescription {
  display: flex;
  flex-direction: column;
  padding: 2% 5%;

  .eventDescriptionBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 60rem) {
      width: 100%;
      flex-direction: column;
    }

    .eventBox {
      width: 30%;
      color: #000;
      font-style: normal;
      line-height: normal;
      max-width: 23rem;

      @media screen and (max-width: 60rem) {
       display: flex;
       flex-direction: column;
       align-self: center;
        padding: 1rem 0;
        width: 100%;
        max-width: 100%;
      }

      .imageEventBox {
        @media screen and (max-width: 60rem) {
          align-self: center;
        }
      }

      .title {
        font-size: 1.25rem;
        @media screen and (max-width: 60rem) {
          align-self: center;
        }
      }
      
      .description {
        @media screen and (max-width: 60rem) {
          text-align: center;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    align-self: center;
    color: #3342a0;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 1rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 60rem) {
      font-size: 1.5rem;
    }
  }
}

.eventConsulting {
  display: flex;
  flex-direction: row;
  padding: 2% 5% 0;
  align-items: center;

  @media screen and (max-width: 60rem) {
    flex-direction: column;
  }

  .eventConsultingImage {
    max-width: 70%;
    max-height: 32rem;
    object-fit: contain;
    padding-right: 2rem;

    @media screen and (max-width: 60rem) {
      max-width: 100%;
      padding-right: unset;
    }
  }

  .eventConsultingTextBox {
    .title {
      font-size: 1.25rem;

      @media screen and (max-width: 60rem) {
        font-size: 1.5rem;
      }

      @media screen and (min-width: 60rem) {
        margin-top: 0;
      }
    }

    .description {
      padding-bottom: 1rem;
      font-weight: 400;

      @media screen and (max-width: 60rem) {
        font-size: 1rem;
      }
    }

    .buttonBox {
      .button {
        margin-top: 1rem;
      }
      @media screen and (max-width: 60rem) {
        width: 100%;
        display: flex;
        justify-content: center;
        .button {
          margin-top: 32px;
          width: 60%;
        }
      }
    }
  }
}

.eventSponsors {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2% 5% 0;

  .header {
    display: flex;
    align-items: center;
    align-self: center;
    color: #3342A0;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 1rem;

    @media screen and (max-width: 60rem) {
      font-size: 1.5rem;
    }
  }

  .eventSponsorsBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.eventBenefits {
  display: flex;
  align-self: center;
  flex-direction: column;
  max-width: 90rem;
  padding: 2% 5%;

  .eventBenefitsImage {
    width: 100%;
    max-height: 37rem;
    object-fit: contain;
  }
  .bottomBox {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;

    .benefitBox {
      width: 100%;
    }

    @media screen and (max-width: 60rem) {
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 8px;
    }

    .title {
      font-size: 1.25rem;
    }
  }
}

.testimonialsSwiper {
  width: 100%;

  .slide {
    display: flex;
    flex-direction: column;
    color: white;
    height: 100%;
    font-family: Nunito, serif;
    background: #4472c2;
    padding: 2% 5% 7%;

    .title {
      font-size: 1.9rem;
      margin: 0.67em 0 0;
    }
  }

  .card {
    display: flex;
    align-items: center;
    font-style: normal;
    line-height: normal;
    flex-direction: row;

    @media screen and (max-width: 60rem) {
      flex-direction: column;
    }

    .description {
      max-width: 65%;
      padding-right: 3rem;

      @media screen and (max-width: 60rem) {
        max-width: 100%;
        padding: 0 0 2rem;
      }
    }

    .userBox {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 60rem) {
        margin-bottom: 1rem;
      }

      .image {
        width: 8rem;
        height: 8rem;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 1rem;

        @media screen and (max-width: 60rem) {
          width: 5rem;
          height: 5rem;
        }
      }
      .userDescriptionBox {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 1.4rem;
          font-weight: 700;
        }

        .title {
          font-size: 1.15rem;
          font-weight: 400;
          white-space: pre;
          margin: 0;
        }
      }
    }
  }
}

.main {
  display: flex;
  color: white;
  font-family: Nunito, serif;
  font-style: normal;
  line-height: normal;
  width: 100%;
  padding: 7% 5%;
  background: #4472c2;
  flex-direction: row;

  @media screen and (max-width: 60rem) {
    flex-direction: column;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 21rem;
    max-width: 45%;
    padding: 1.5rem;

    @media screen and (max-width: 60rem) {
      max-width: 100%;
      align-items: center;
      padding: 0;
    }

    .title {
      width: 90%;
      font-weight: 700;
      line-height: 1.4;
      margin: 0;
      @media screen and (max-width: 60rem) {
        text-align: center;
      }
    }

    .description {
      font-size: 1.25rem;
      line-height: 1.4;
      font-weight: 400;
      font-family: Nunito, serif;
      width: 90%;
      margin: 1rem 0;
      white-space: pre-line;

      @media screen and (max-width: 60rem) {
        text-align: center;
      }
    }

    .emailInput {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      padding-top: 3rem;

      .inputField {
        width: 90%;
        max-width: 31.75rem;
        border-radius: 200px !important;
        background: white;
        color: #5b5b5b;

        margin-bottom: unset !important;

        input {
          border-radius: 200px !important;
          padding-left: 1rem;
        }

        :global {
          .MuiInputBase-root {
            border-radius: 200px !important;
          }
          .MuiFormLabel-root {
            left: 0.8rem;
          }
        }
      }

      @media screen and (max-width: 60rem) {
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding: 1.5rem 0;

        .button {
          font-size: 0.8rem;
          width: 30%;
        }
      }
    }
  }

  .carousel {
    width: 70%;
    display: flex;
    padding: 1.5rem;

    .iframe {
      border: 0;
      max-width: 50rem;
      height: 22rem;
      object-fit: contain;
      border-radius: 10px;

      @media screen and (max-width: 60rem) {
        height: 12rem;
      }
    }

    @media screen and (max-width: 60rem) {
      max-height: 100%;
      width: 100%;
      padding: 0.5rem 0;
      justify-content: center;
    }
  }
}

.learnMore {
  display: flex;
  color: white;
  flex-direction: column;
  background: #FF5252;
  padding: 2% 5% 7%;

  .title {
    font-size: 1.9rem;
  }

  .description {
    max-width: 50%;

    @media screen and (max-width: 60rem) {
      margin-top: 0;
      max-width: 85%;
    }
  }

  .emailInput {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    padding-top: 2rem;

    @media screen and (max-width: 60rem) {
      flex-direction: row;
      width: 100%;
      align-items: center;
      padding: 1rem 0;
      max-height: 100%;
      gap: 1rem;
    }

    .inputField {
      border-radius: 200px !important;
      background: white;
      color: #5b5b5b;
      max-width: 75%;
      margin-bottom: unset !important;

      @media screen and (max-width: 60rem) {
        max-width: 65%;
      }

      input {
        border-radius: 200px !important;
        padding-left: 1rem;
      }

      :global {
        .MuiInputBase-root {
          border-radius: 200px !important;
        }
        .MuiFormLabel-root {
          left: 0.8rem;
        }
      }
    }

    .button {
      max-width: 25%;
      border-radius: 100px;
      background: #3342A0;
      height: 100%;
      max-height: unset;

      @media screen and (max-width: 60rem) {
        font-size: 0.8rem;
        max-width: 100%;
      }
    }
  }
}

.modal {
  padding: 1.9rem 3% 1.6rem 3%;
  height: 20rem;
  max-width: 36rem;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.25rem;
  gap: 2rem;

  .header {
    min-height: 3rem;
    font-weight: bold;
    font-size: 1.9rem;
    color: #3342A0;
    min-width: 20rem;
  }
}
.closeBtn {
  display: flex;
  align-self: flex-end;
  position: absolute !important;
  right: 1rem;
  top: 1rem;
}

.buttonBoxLinkToList {        
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  .linkButtonToList {
    width: 100%;
  }
}

.loader {
  z-index: 999;
  top: 50%;
  left: calc(50% - 30px);
}

.backButton {
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
}

.main {
  display: flex;
  background-color: #fff;
  max-width: 64rem;
  flex-direction: column;
  overflow: hidden;
}
.recordList {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(285px,1fr));
  //grid-template-rows: repeat(auto-fill, minmax(450px,1fr));
}

.loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.dark-gray {
  color: var(--dark-gray);
}
.gray {
  color: var(--gray);
}

.indigo-blue {
  color: var(--indigo-blue);
}

.neo-red {
  color: var(--neo-red);
}

.surface {
  color: var(--surface);
}

.pure-red {
  color: var(--pure-red);
}

.pure-green {
  color: var(--pure-green);
}

.bg-surface {
  background-color: var(--surface);
}

.bg-dark-surface {
  background-color: var(--dark-surface);
}

.bg-almost-black {
  background-color: var(--almost-black);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-pure-red {
  background-color: var(--pure-red);
}

.bg-neo-red {
  background-color: var(--neo-red);
}

.bg-pure-green {
  background-color: var(--pure-green);
}

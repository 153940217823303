.my-record-details__media {
  object-fit: cover;
  width: 100%;
  height: 40vh;
  min-height: 100px;
  max-height: 200px;
  border-radius: 10px;
}

.my-records-details__buttons {
  min-width: 280px;
}

.my-record-details__category {
  font-size: 10px;
}
.my-record-details__column-status {
  font-size: 1rem;
  padding-bottom: 16px;
}
@media screen and (max-width: 60em) {
  .my-record-details__column-status {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 30em) {
  .my-records-details__buttons {
    min-width: 320px;
  }
}

@media screen and (min-width: 60em) {
  .my-record-details__column-left {
    width: 58%;
  }

  .my-record-details__column-right {
    width: 40%;
  }
}
.qr-code-apple-pass {
  align-self: center;
  display: flex;
  flex-direction: column;
}
.Add_to_Apple_Wallet_RGB {
  margin-bottom: 10px;
}

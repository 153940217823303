.body-l-t-u {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pin {
    text-align: center;
}

.bottom-btn {
    width: 100%;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 8%;

  .shield_level {
    position: relative;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.18));
    width: 2.4rem;
    height: 2.97rem;
    font-size: 1.5rem;
  }

  .text {
    color: white;
    font-size: 0.65rem;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    top: 55%;
  }
}
.modal {
  padding: 1.9rem 3% 1.6rem 3%;

  .modalContainer {
    display: flex;
    flex-direction: column;
    min-height: 20rem;
    max-width: 28rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .header {
      min-height: 3rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: var(--pure-red);
      text-transform: uppercase;
    }

    .header2 {
      min-height: 3rem;
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .modalText {
      font-size: 1.25rem;
      max-width: 34rem;
      min-width: 21rem;
      margin-bottom: 1rem;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .linkButton {
        font-size: 1rem;
        background-color: unset;
        border: unset;
        color: blue;
        cursor: pointer;
      }
    }
  }

  .confirmationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    gap: 2rem;
    min-width: 15rem;

    .header {
      font-weight: bold;
      font-size: 1.9rem;
      color: #3342A0;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
}
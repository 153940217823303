.main {
  display: flex;
  color: var(--indigo-blue);
  font-family: Nunito, serif;
  gap: 40px;

  @media screen and (max-width: 60rem) {
      flex-direction: column;
      gap: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 21rem;

    @media screen and (max-width: 60rem) {
      align-items: center;
    }

    .title {
      font-size: 2.25rem;
      font-family: Nunito, serif;
      font-weight: 800;
      margin: 0;
      @media screen and (max-width: 60rem) {
        text-align: center;
      }
    }

    .description {
      font-family: Nunito, serif;
      width: 100%;
      max-width: 400px;
      margin: 1rem 0;
      @media screen and (max-width: 60rem) {
        text-align: center;
      }
    }
  }

  .carousel {
    width: 70%;
    max-height: minmax(0,20.625rem);
    height: 100%;

    @media screen and (max-width: 60rem) {
      width: 100%;
    }
  }
}

.informationText {
  font-family: Nunito, serif;
  margin: 10px 0 10px 5px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.text {
  color: var(--dark-gray);
  margin: 1rem .5rem 1rem 0;
  font-size: 1rem;
  line-height: 1.25;

  @media screen and (max-width: 60em) {
    font-size: 0.875rem;
  }
}

.container {
  display: grid;
  grid-template-columns: minmax(0, 1.5fr) minmax(0, 1fr);
  column-gap: 1.5rem;

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .text {
      margin: 0 0.8rem 0 0.6rem;
    }
  }

  .facebookIcon {
    color: #4267B2;
    font-size: 3.2rem;
  }

  .pinterestIcon {
    color: #BD081C;
    font-size: 3.2rem;
  }

  .shareLink {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--gray);
    width: fit-content;
    padding: 0.3rem 1rem;
    gap: 1rem;
    
    .copy {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 60em) {
    grid-template-columns: 1fr;
  }
}

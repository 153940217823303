.border-none,
.border-none:focus {
  border: none;
}

.border--gray {
  border-color: var(--gray);
}

.border--dark-surface {
  border-color: var(--dark-surface);
}

.border-bottom--dark-surface {
  border-bottom-color: var(--dark-surface);
}

.header {
  padding: 10px;
  width: 100%;
}

.under-header {
  height: 80px;
}

.header__login-avatar {
  position: absolute;
  right: 20px;
}

.profile-icon.switch-profile {
  position: absolute;
}

.menu-icon.icon-button {
  position: absolute;
}

.MuiBadge-root.shopping-cart-icon {
  position: absolute;
  right: 58px;
}

@media only screen and (min-width: 60em) {
  .header__login-avatar {
    display: none;
  }

  .menu-icon.MuiIconButton-root {
    display: none;
  }

  .MuiBadge-root.shopping-cart-icon {
    right: 174px;
  }

  .MuiBadge-root.shopping-cart-icon--authenticated {
    right: 74px;
  }
}

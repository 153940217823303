.headerButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0;
  margin-right: 2rem;
  max-width: 4rem;
}

.modal {
  padding: 1.9rem 1.3% 1.6rem 1.3%;
  min-width: 22rem;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35rem;

  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .header {
    color: #3342A0;
    font-weight: 800;
    font-size: 1.9rem;
    text-align: center;
    margin: 0;
  }

  .text {
    color: black;
    font-size: 1.25rem;
    text-align: center;
    margin: 1.5rem 0;
  }

  .button {
    width: 11rem;
    border-radius: 100px;
    font-size: 1rem;
  }

  .emailInput {
    border-radius: 200px;
    margin: 0 1rem 0 0;

    input {
      border-radius: 200px !important;
      padding-left: 1.3rem;
    }

    :global {
      .MuiInputBase-root {
        border-radius: 200px !important;
      }

      .MuiFormLabel-root {
        left: 0.8rem;
      }
    }
  }

  .bottomText {
    text-align: center;
    margin-top: 1.5rem;
  }
}
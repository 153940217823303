.table_label {
  font-size: 1rem;
  font-family: Nunito, serif;
  font-weight: 800;
}
.even_style {
  background-color: lightgray;
}

.table_row td {
  font-size: 1rem;
  font-family: Nunito, serif;
}

.table_row .MuiTableCell-root {
  padding: 0.5rem;
  font-family: Nunito, serif;
}

.no-more-content {
  color: #606c76 !important;
  text-align: center !important;
  font-size: 1rem !important;
  font-family: Nunito, serif;
}

.no-more-content.mloader {
  padding: 0.5rem 45%;
  font-family: Nunito, serif;
}

.page {
  margin: 2.5rem 0;
}

.loader {
  top: 50%;
  left: calc(50% - 30px);
  z-index: 999;
}

.accordion {
  padding: 0.5rem;
  border: 1px var(--light-gray) solid;
}

.details {
  height: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;

  .ifr {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 75rem;

    @media only screen and (max-width: 60em) {
      height: 24rem;
    }
  }
}


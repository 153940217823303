.top {
  margin: 1rem 0;
}

.description {
  font-size: 1.125rem;
  padding-bottom: 1rem;
  line-height: normal;
}

.radioRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .radioOption {
    display: flex;
    align-items: center;

    .optionInput {
      display: flex;
      flex-direction: row;
      padding-left: 0.5rem;
      gap: 0.2rem;

      .amountInput {
        width: 2.5rem;
        height: 2rem;
        border-radius: 5px;
        padding-right: 5px;
        background: #F2F2F2;
        padding-left: 5px;
        max-width: 250px;
        border: 1px solid #A5A5A5;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .error {
        color: red;
      }
    }
  }
}

.flexGrid {
  align-items: stretch;
}
.swiper {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 79rem;
  align-self: center;

  .swiperSlide {
    border: 1px solid white;
    padding: 1rem;
    width: 13.5rem;
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  .moreBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0 0.8rem;
    color: var(--indigo-blue);

    &:hover {
      cursor: pointer;
    }
  }
}



.main {
  padding: 1rem;

  .record {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;

    img {
      max-width: 20rem;
      padding: 0.5rem;
      border-radius: 0.8rem;
    }

    .info {
      padding: 0.5rem;

      .title {
        padding-bottom: 0.5rem;
        font-family: Nunito, serif;
        font-weight: 800;
        color: var(--indigo-blue);
      }

      .description {
        padding-bottom: 0.5rem;
        font-family: 'Nunito', serif;
      }
    }
  }
}

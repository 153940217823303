.productForm {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding-bottom: 2rem;
  flex-wrap: wrap;

  .infoColumn {
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    padding-top: 0.8rem;
    padding-left: 0.5rem;

    .info {
      font-size: 0.675rem;
      font-weight: 700;
      color: var(--dark-gray);
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-right: 1rem;
    }

    .description {
      color: var(--dark-gray);
      padding-top: 1rem;
      margin-right:.5rem;
      line-height:1.25
    }

    .select {
      width: 100%;
      max-width: 25rem;
    }
  }

  .buyColumn {
    display: flex;
    flex-direction:column;
    align-items: flex-end;
    padding-top: 1.5rem;

    .priceTag {
      font-size: 1.5rem;
      padding: 0.5rem;
      font-weight: 700;
      margin: 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      padding: 1rem 0.5rem 1.5rem;
      gap: 2rem;

      .button {
        padding: 0.4rem 1rem;
        font-size: 0.75rem;
      }

      @media screen and (max-width: 60em) {
        flex-direction: row;
        padding: 0;
        width: 100%;
      }
    }

    @media screen and (max-width: 60em) {
      align-items: flex-start;
      padding: 0;
      width: 100%;
    }
  }

}
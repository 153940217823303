.imageList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  img {
    max-width: 10rem;
    border-radius: 10px;
  }
}

.titlePage {
  text-align: center;
}

.description {
  text-align: center;
}

.recordList {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  top: 0;
  align-items: start;
}

.loader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.screen {
  max-width: 90%;
}

.outlined {
  margin-bottom: 5px;
}
.radioRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  .radio {
    max-height: 2.5rem;
  }
}

.templateDescription {
  line-height: 2.5rem;
  border-radius: 5px;

  &.disabled {
    background: #F4F4F4;
  }
}

.templatedPart {
  text-decoration: underline;
}

.input {
  position: relative;
  width: fit-content;
  margin: 0;

  input {
    background: #F2F2F2;
    border-radius: 5px;
    padding-right: 34px;
    padding-left: 5px;
    height: 2rem;
    width: 100%;
    max-width: 250px;
    border: 1px solid #A5A5A5;
  }

  .toolTip {
    position: absolute;
    top: -14px;
    right: -5px;
  }
}

.container {
  display: flex;
  justify-content: end;
  
  @media screen and (max-width: 60rem) {
    justify-content: center;
}
  a {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;

    @media screen and (max-width: 60rem) {
      justify-content: center;
  }
  }
}

.image-decoration {
  z-index: -1;
  max-width: 24rem;
}

.image-decoration--left {
  left: -100px;
}

.image-decoration--right {
  right: -100px;
}

@media screen and (min-width: 1440) {
  .image-decoration--left {
    left: 0;
  }

  .image-decoration--right {
    right: 0;
  }
}

.page {
    width: 100%;
    height: 100%;
    max-width: 74.4rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 64px;
    padding-right: 64px;
    @media screen and (max-width: 490px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.loader {
    z-index: 999;
    top: 50%;
    left: calc(50% - 30px);
}

.textInfo {
    font-size: .875rem;
}

.pinInput {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: center;
  }

.buttonsDiv {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: var(--spacing-medium);
  
    .buttonChoice {
      width: 8rem;
    }
  
    @media screen and (max-width: 961px) {
      flex-direction: column;
  
      .buttonChoice {
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
      }
  }
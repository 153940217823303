.toaster {
  min-height: 50px;
  position: sticky;
  transition-property: transform;
  transition-duration: 1s;
  animation-timing-function: ease-in-out;
  transform: translate(0, -110%);
  margin-bottom: -50px;
}

.toaster::first-letter {
  text-transform: uppercase;
}

.toaster--active {
  transform: translate(0, 0);
  top: 0;
}

.nunito-regular {
  font-family: Nunito, serif;
}

.nunito-extra-bold {
  font-family: Nunito, serif;
  font-weight: 800;
}

.nunito-bold {
  font-family: Nunito, serif;
  font-weight: 700;
}

.openSans-regular {
  font-family: Nunito, serif;
}

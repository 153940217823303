.subTitle {
    float: none;
    color: black;
    font-family: Nunito, serif;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    padding-top: 16px;
    padding-bottom: 16px;
} 

.imageDiv {
    overflow: hidden;
}

.imageLeft {
    width: 10rem;
    object-fit: cover;
    float: left;
    padding-right: 10px;
}
.imageRigth {
    width: 10rem;
    object-fit: cover;
    float: right;
    padding-left: 10px;
}
.paragraph {
    font-size: 1rem;
    color: #000000;
    font-family: Nunito, serif;
}

.button_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.button {
    width: 40%;
}

.activityList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
    .cardInfo {
        flex-basis: 23%;
        flex-grow: 0;     
        flex-shrink: 0;
        width: 100%;
    }
}
.cardName {
    height: 2rem;
    font-weight: 700;
}
.cardImage {
    width: 100%;
    aspect-ratio: 3/2;
}
.valuesList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    .cardInfo {
        flex-basis: 30%;
        flex-grow: 0;     
        flex-shrink: 0;
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .valuesList {
        .cardInfo {
            flex-basis: 80%;
        }
    }
}
@media screen and (max-width: 1000px) {
    .activityList {
        .cardInfo {
            flex-basis: 45%;
        }
    }
}
@media screen and (max-width: 600px) {
    .activityList {
        .cardInfo {
            flex-basis: 80%;
        }
    }
}
    

@media screen and (max-width: 30em) {
    .button_wrapper {
        flex-direction: column;
    }
      
    .button {
        width: 100%;
    }
}
  
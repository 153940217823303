.context-menu .MuiPaper-root {
  background-color: var(--surface);
  border-radius: 9px;
  box-shadow: 8px 8px 5px 0 rgba(0, 0, 0, 0.25);
}

.context-menu.MuiListItem-root {
  padding: 0;
  margin: 0;
}

.context-menu__item.MuiListItem-root {
  display: flex;
  justify-content: flex-end;
  color: var(--dark-gray);
  padding: 10px 20px 10px 10px;
  min-width: 160px;
}

.component {
  display: flex;
  flex-direction: column;
  padding: 2% 0 0 5%;

  .swiper {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .swiperSlide:not(:last-child) {
    padding-right: 2rem;
  }

  .swiperSlide {
    width: 34rem;
    @media screen and (max-width: 961px) {
      width: 20rem;
    }
    @media screen and (max-width: 390px) {
      width: 90%;
    }
  }
  .swiperSponsors {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    max-width: 100%;
    box-sizing:border-box;
  }

  .swiperSponsorsSlide:not(:last-child) {
    padding-right: 2rem;
    @media screen and (max-width: 60rem) {
      padding-right: 0.5rem;
    }

  }

  .swiperSponsorsSlide {
    flex-grow:1;
    width: 15rem;
    height: 10rem;
    align-content: center;
    @media screen and (max-width: 60rem) {
      width: 4rem;
      height: 8rem;
    }
  }

  .header {
    display: flex;
    align-items: center;
    align-self: center;
    color: #3342A0;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 1rem;
    margin-bottom: 0.5rem;

    .arrow {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #AEC3E8;
    }

    @media screen and (max-width: 60rem) {
      font-size: 1.5rem;
      margin-bottom: 0px;
      margin-top: 2rem;

      .arrow {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.carousel {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}

.card {
  display: flex;
  flex-direction: column;

.imageDiv {
  width: 100%;
  height: 22.5rem;
  position: relative;
  .imageOrVideo {
    object-fit: contain;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

    @media screen and (max-width: 60rem) {
      height: 15rem;
    } 
    
  .image {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

  .cardDescription {
    width: 100%;
    background: #4472c2;
    font-style: normal;
    line-height: 1.6rem;
    color: #fff;
    padding: 1rem 1rem 1.25rem;
    height: 5.6rem;

    .title {
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0;
    }
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.5rem;
    }
  }
}

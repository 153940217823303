.pinInput {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: center;
}

.loader {
    z-index: 999;
    top: 50%;
    left: calc(50% - 30px);
}

.buttonsDiv {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: var(--spacing-medium);

    .buttonChoice {
      width: 8rem;
    }

    @media screen and (max-width: 961px) {
        flex-direction: column;
        .buttonChoice {
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.modalBody{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
}

.modalBody span {
    text-align: center;
}

.modalBtn {

}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input {
    margin-top: 2rem
  }

  .submitButton {
    margin-top: 4rem;
    width: 256px;
  }

  .link {
    margin-top: 2rem;
  }
}

.text {
  margin: 0;
}
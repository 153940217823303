.select.MuiTextField-root {
  margin-bottom: 1rem;
}

.select .MuiInputBase-root {
  background-color: var(--surface);
  border: 1.5px solid #ffffff;
  border-radius: 10px;
}

.select .MuiInputBase-root.Mui-disabled {
  background-color: var(--surface);
  color: var(--dark-gray);
}

.select .MuiInputBase-root:hover {
  background-color: var(--dark-surface);
}

.select .MuiSelect-select:focus {
  border-radius: 10px;
  background-color: var(--dark-surface);
}

.select .MuiInputBase-root,
.select .MuiFormLabel-root,
.select .MuiFormHelperText-root,
.select .MuiSelect-select {
  color: var(--dark-gray);
}

.select .MuiInputBase-root.Mui-focused {
  border: 1.2px solid var(--indigo-blue);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.select .MuiFormLabel-root.Mui-focused {
  color: var(--indigo-blue);
}

.select .MuiInputBase-root.Mui-error,
.select .MuiFormLabel-root.Mui-error,
.select .MuiFormHelperText-root.Mui-error {
  color: var(--pure-red);
}

.select .MuiInputBase-root.Mui-error.Mui-focused {
  border: 1.2px solid var(--pure-red);
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.select .MuiInputBase-input {
  padding-top: 25px;
  padding-bottom: 8px;
}

.select-item.MuiMenuItem-root {
}

.select-item.Mui-focusVisible,
.select-item.Mui-selected {
  background-color: var(--dark-surface);
}

.outlined {
  margin-bottom: 10px;
  margin-left: 2px;
}

.main {
  padding: 1rem;

  .inputRow {
    display: flex;
    flex-direction: row;

    .recordIdInput {
      margin-right: 1rem;
    }
  }


  .content {
    display: none;

    &.visible {
      display: block;
    }
  }

  .record {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;

    img {
      max-width: 20rem;
      padding: 0.5rem;
      border-radius: 0.8rem;
    }

    .info {
      padding: 0.5rem;

      .title {
        padding-bottom: 0.5rem;
        font-family: Nunito, serif;
        font-weight: 900;
        color: var(--indigo-blue);
      }

      .description {
        padding-bottom: 0.5rem;
        font-family: Nunito, serif;
      }

      .status {
        color: #4caf50;

        &.broken {
          color: red;
        }
      }
    }
  }
}

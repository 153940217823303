.header {
  display: flex;
  background-color: #ffc300;
  overflow: hidden;
  align-items: center;

  .yellow {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .center {
      width: 100%;
      padding: 1rem 4rem;
      max-width: 74.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: white;
      }

      .stars {
        display: flex;
        align-items: center;

        .star1 {
          font-size: 5rem;
          color: white;

          @media only screen and (max-width: 50rem) {
            font-size: 3rem;
          }
        }

        .star2 {
          font-size: 10rem;
          color: white;
          margin-left: 2rem;

          @media only screen and (max-width: 50rem) {
            font-size: 6rem;
            margin: 0;
          }
        }
      }

      @media only screen and (max-width: 50rem) {
        padding: 1rem;
      }
    }
  }

  .congratulations {
    background: linear-gradient(to right, #e5a908, #f5b20b);
    position: absolute;
    right: 0;
    transform: translateY(100%) rotate(38deg);
    transform-origin: bottom right;
    padding: 2.5rem 4.5rem 2.5rem 8rem;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;

    @media only screen and (max-width: 50rem) {
      right: -1rem;
      top: -1rem;
    }
  }
}
.contentSpan {
  :global(.image-style-align-left) {
    margin: 14px 24px 14px 0px;
    float: left;
  }

  :global(.image-style-align-right) {
    float: right;
    margin: 14px 0px 14px 24px;
  }
  :global(.image-style-block-align-right) {
    margin-left: auto;
    margin-right: 0;
  }
  :global(.image-style-block-align-left) {
    margin-left: 0;
    margin-right: auto;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  img {
    height: fit-content;
  }
  figure {
    display: block;
    margin: 0 auto;
  }
  figcaption {
    text-align: center;
    caption-side: bottom;
    color: var(--ck-color-image-caption-text);
    font-size: 0.75em;
    outline-offset: -1px;
    padding: 0.6em;
    word-break: break-word;
  }
  blockquote {
    border-left: 5px solid #303f9f;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  table {
    display: table;
    margin: 0.5rem auto;
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  th {
    background-color: #f2f2f2;
    text-align: left;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #e5e5e6;
  }
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .image {
      width: 12rem;
      height: 12rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .description {
      font-size: 1rem;
      max-width: 9.5rem;
      padding-bottom: 1rem;

      @media screen and (max-width: 60rem) {
        text-align: center;
        max-width: 100%;
      }
    }
    @media screen and (max-width: 60rem) {
      width: 100%;
    }
  }
}
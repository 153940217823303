:root {
  --almost-black: #212121;
  --indigo-blue: #303f9f;
  --blue: #3f51b5;
  --neo-red: #ff5252;
  --pure-red: #f2453d;
  --gray: #bdbdbd;
  --light-gray: #E6E6E6;
  --dark-gray: #595959;
  --slate-gray: #808080;
  --surface: #f8f8f8;
  --dark-surface: #f4f4f4;
  --pure-green: #4caf50;
}

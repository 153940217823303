.record-details__media-tooltip {
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.shouldPayNotification {
  color: red
}

@media screen and (min-width: 30em) {
  .record-details__media-tooltip {
    width: 285px;
  }
}

.codeButton.MuiButton-root {
  width: fit-content;
  margin-bottom: 0.5rem;
  text-transform: none;
  font-size: 1rem;
}

.codeRaw {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 10px;
  align-items: center;
  margin-bottom: 1rem;
}

.codeInput.input-text{
  margin-bottom: 0;
}

.pointer_ul {
  font-weight: 800;
  list-style-type: disc;
  padding: 0px 0px 0px 20px;
}

.pointer_ul > li {
  margin: 5px 0px 5px 0px;
}
.submit-include {
  font-weight: 800;
}
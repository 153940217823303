.container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  width: fit-content;
}

.card {
  border: 1px solid var(--gray);
  border-radius: 10px;
  padding: 0 0.7rem;
  width: 40%;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  .btn {
    position: absolute;
    background: var(--neo-red);
    color: white;
    padding: 0.7rem 1rem;
    border-radius: 200px;
    font-size: 0.9rem;
    text-transform: uppercase;
    bottom: 2.5rem;
  }

  .shiftBtn {
    right: 1rem;
  }

  @media screen and (max-width: 60em) {
    width: 100%;
  }
}

.card:hover {
  cursor: pointer;
}

.image {
  height: 11rem;
  object-fit: contain;
  align-self: start;
}

.borderedContainerStyle  {
  padding: 3rem 0.6rem;
  text-align: center;
  margin: 0;
}
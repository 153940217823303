.main {
    position: relative;
    display: flex;
    justify-content: center;
    height:318px;
    width: 225px;
}

.badge {
    position: absolute;
    width: 7rem;
    height: 11rem;
    bottom: 0;
}

.star_shield {
    width: 225px;
    height: 225px;
    position: absolute;
    display: flex;
    justify-content: center;

    .star {
        position: absolute;
    }
}

.shield_level {
    position: absolute;
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.18));
    font-size: 3.5rem;
    top: 23%;

    img {
        width: 5.8rem;
        height: 7.1rem;
    }
}
.rules__header-wrapper {
  font-size: 24px;
  padding-top: 45px;
  padding-bottom: 15px;
}

.rules__items-wrapper {
  height: 420px;
  padding-left: 0;
  padding-bottom: 100px;
}

@media screen and (min-width: 30em) {
  .rules__items-wrapper {
    height: 300px;
  }
}
